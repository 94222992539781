.user-dashboard-section{
    padding-top: 15px;
}
.user-dashboard-section .row>* {
    padding-right: 0;
    padding-left: 0;
}
.user-dashboard-email{
    text-align: center;
    padding-bottom: 10px;
}
.user-dashboard-email p{
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
}