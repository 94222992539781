.trade-app-area {
  padding-top: 80px;
}

.py-20 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important;
}

.px-xl {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.relative {
  position: relative !important;
}

.smooth_style {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gap-\[80px\] {
  grid-gap: 80px !important;
  gap: 80px;
}

.flex {
  display: flex !important;
}

.justify-between {
  justify-content: space-between !important;
}

.items-center {
  align-items: center !important;
}

.flex-col {
  flex-direction: column !important;
}

.w-1\/2 {
  width: 50% !important;
}

.h-auto {
  height: auto !important;
}

/* @media (min-width: 1023px) {
    .desktop\:h-\[610px\] {
        height: 610px !important;
    }
} */

@media (min-width: 767px) {
  .noH5\:hidden {
    display: none !important;
  }
}

.w-auto {
  width: auto !important;
}

.mt-m {
  margin-top: 16px !important;
}

.mb-xl {
  margin-bottom: 24px !important;
}

.text-t-primary {
  color: #fff;
}

.items-start {
  align-items: flex-start !important;
}

.text-headline2 {
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 48px !important;
}

.gap-5xl {
  grid-gap: 40px !important;
  gap: 40px !important;
}

.mt-\[0px\] {
  margin-top: 0 !important;
}

.p-l {
  padding: 20px !important;
}

.border-\[\#2B3139\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(43 49 57 / var(--tw-border-opacity)) !important;
}

.border-solid {
  border-style: solid !important;
}

.border {
  border-width: 1px !important;
}

.rounded-\[24px\] {
  border-radius: 24px !important;
}

.bg-white {
  background-color: #fff !important;
}

.p-xs {
  padding: 10px !important;
}

.rounded-\[8px\] {
  border-radius: 8px !important;
}

.ml-xl {
  margin-left: 24px !important;
}

.text-t-third {
  color: #848e9c !important;
}

.text-body1,
.text-body2 {
  font-weight: 400 !important;
}

.text-body1 {
  font-size: 16px !important;
  line-height: 24px !important;
}

.text-headline6 {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
}

.mt-2xs {
  margin-top: 8px !important;
}

.gap-\[64px\] {
  grid-gap: 64px !important;
  gap: 64px !important;
}

.w-\[436px\] {
  width: 436px !important;
}

.p-2xs {
  padding: 8px !important;
}

.rounded-\[8px\] {
  border-radius: 8px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-\[112px\] {
  width: 112px !important;
}

.text-\[40px\] {
  font-size: 40px !important;
}

.bn-svg {
  font-size: inherit;
  height: 1em;
  width: 1em;
}

.text-subtitle1 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.hover\:bg-\[\#252A32\]:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 42 50 / var(--tw-bg-opacity)) !important;
}

@media (min-width: 600px) and (max-width: 900px) {
  .trade-app-area {
    padding-top: 30px;
  }
 
  .gap-\[80px\] {
    grid-gap: 10px !important;
  }
  .mobile-trade-app-flex {
    flex-direction: column !important;
  }
  .text-headline2 {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .mobile-trade-app-content {
    justify-content: center !important;
    align-items: center !important;
  }
  .w-\[436px\] {
    width: auto !important;
  }
  .gap-\[64px\] {
    gap: 0 !important;
  }
}

@media (min-width: 0px) and (max-width: 599.9px) {
  .trade-app-area {
    padding-top: 20px;
    text-align: center !important;
  }
  .trade-app-area-flex-column {
    flex-direction: column !important;
  }
  .trade-app-area img {
    margin: auto !important;
  }
  .trade-app-area .w-1\/2 {
    width: 100% !important;
  }
  .gap-\[80px\] {
    gap: 0 !important;
  }
  .text-headline2 {
    font-size: 20px !important;
    line-height: 28px !important;
    margin: auto;
    padding: 30px 0;
  }
  .w-\[436px\] {
    width: auto !important;
  }
  .gap-\[64px\] {
    gap: 0px !important;
  }
  .mobile-trade-app-scan {
    margin-left: 0 !important;
    padding-top: 20px;
  }
}
