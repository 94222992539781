.forex-area{
    padding: 100px 0 50px 0;
}

.forex-area .docs-container .docs-header {
    /* background: rgba(41, 47, 59, .9); */
    padding-bottom:  40px ;
}

.forex-area .docs-container .docs-header h1 {
    color: #fe5722;
    font-size: 26px;
    margin: 0;
}

.forex-area .docs-container .docs-header .breadcrumb {
    background: transparent;
    margin: 0;
    padding: 0;
}


.forex-area .docs-container .docs-header .breadcrumb li, .forex-area .docs-container .docs-header .breadcrumb li a {
    font-size: 13px;
    color: #9f9f9f;
    cursor: default;
}

.breadcrumb>li {
    display: inline-block;
}
.forex-area .docs-container .docs-header .breadcrumb li.active {
    color: #fff;
}

.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/ ";
}

.forex-area .docs-container .docs-main {
    /* background: #282a36; */
    margin: 0;
    padding-bottom:  40px ;
}

.forex-area .docs-container .docs-main p {
    color: #fff;
    margin: 0;
    padding-bottom: 12px;
    text-align: justify;
    font-size: 15px;
}

.forex-area .docs-container .docs-body {
    /* background: #fff; */
    padding-bottom: 50px;
}
.forex-area-docs{
    padding-bottom: 15px;
}
.forex-area .content h2{
    color: rebeccapurple;
}

/* .forex-area .docs-container .docs-body .docs {
    margin: 0 0 40px !important;
    padding: 0 10px !important;
} */

.forex-area .docs-container .docs h1 {
    color: #ef3617;
    font-size: 17px;
    font-family: Roboto-Regular, sans-serif !important;
    margin-top: 15px;
   padding-bottom: 10px;
}

.forex-area .docs-container .docs-body .docs p {
    padding: 0;
    margin-bottom: 20px;
    --tw-text-opacity: 1;
    color: rgb(172 172 172 / var(--tw-text-opacity));
}
.forex-area .docs-container .docs p {
    text-align: justify;
}



.forex-area .docs-container .docs-body .docs h1 {
    margin-top: 0;
}

.forex-area .docs-container .docs h1 {
    color: #ef3617;
    font-size: 17px;
    font-family: Roboto-Regular, sans-serif !important;
    margin-top: 15px;
    margin-bottom: 5px;
}

.forex-area .docs.traders h1 {
    margin-bottom: 20px !important;
}

.forex-area .docs-container .docs-body .docs.has-icon .icon {
    float: left;
    padding: 0;
    width: 130px;
    padding-bottom: 20px;
}

.forex-area .docs-container .has-icon .icon .commercial {
    width: 98px;
    height: 113px;
    background: url("./image1.png") no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
}

.docs.has-icon:after {
    content: "";
    display: block;
    clear: both;
}

.forex-area .docs-container .docs-body .docs:last-child {
    margin-bottom: 0 !important;
}

.speculative  {
    width: 98px;
    height: 113px;
    background: url("./image2.png") no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: inline-block;
}



/* //////////////////////////////////////////////////// */

.forex-key-features h5{
    font-size: 25px;
    padding: 10px 0;
    color: #fff;
    font-weight: 600;
}

.forex-key-features p{
    font: 16px Open Sans, sans-serif;
    line-height: 30px;
    color: #D1D5DB;
    padding-bottom: 15px;
}
.forex-key-features p:nth-child(4){
    font-style: oblique;
}
.forex-key-features p span{
   font-size: 18px;
   font-weight: 600;
   color: #fff;
}

.forex-key-features{
    padding-top: 10px;
}
 
.benefits-gold-investment-services-area{
    padding-top: 10px;
}
 

.forex-area-Market{
    padding-bottom: 20px;
}

@media (min-width: 0px) and (max-width: 600px) {
    .forex-area .docs-container .docs-body .docs.has-icon .icon{
        float: none;
        margin: auto;
    }
    .forex-area-Market .content{
        text-align: center;
    }
    .forex-area .docs-container .docs-body {
        padding-bottom: 0px;
    }
  }