.news-section-title {
  padding: 80px 0;
  text-align: center;
  background-color: #1d113d;
  margin-top: -80px;
}
.news-section-title h5 {
  font-size: 35px;
  line-height: 45px;
  color: #fff;
}
.main-news{
    padding: 40px 0;
}
.news-tem{
    display: flex;
    justify-content: end;
    align-items: center;
}
.news-tem p{
   font-size: 18px;
   color: #fff;
   font-weight: 600;
   line-height: 25px;
   cursor: pointer;
   margin-left: 20px;
}
.news-tem p svg{
   font-size: 18px;
   color: #fff;
   font-weight: 600;
   line-height: 25px;
   cursor: pointer;
   padding-right: 5px;
}

.news-tem .active{
    color: #0166FC;
}
.news-tem .active  svg{
    color: #0166FC;
}

.news-lists-item{
    display: flex;
}
.news-lists-items{
    padding-top: 40px;
}
.news-lists-items .news-lists-item {
    /* box-shadow: #1d113d  5px 5px, #1d113d  10px 10px; */
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    margin-bottom: 30px;
    border-radius: 10px;
}
.news-lists-items .news-lists-item img{
    width: 400px;
    height: auto;
    padding-right: 30px;
}
.news-lists-items .news-lists-item .news-content{
    padding: 20px 0;
}
.news-lists-items .news-lists-item h4{
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    line-height: 28px;
    width: 90%;
    padding-bottom: 10px;
}
.news-lists-items .news-lists-item p{
    font-size: 16px;
    color: #b4b3b3;
    font-weight: 400;
    line-height: 25px;
    width: 90%;
    padding-bottom: 10px;
}
.news-lists-items .news-lists-item span{
    font-size: 16px;
    color: #b4b3b3;
    padding-bottom: 20px;
    display: block;
}
.news-lists-items .news-lists-item a{
    font-size: 18px;
    color: #b4b3b3;
    background-color: #1d113d;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
}
.news-lists-items .news-lists-item a:hover{
    color: #fff;
    background-color: #0166FC;
}





.news-grids-area .hover{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    border-radius: 10px;
}



 .news-grids-area  .module {
    min-width: 270px;
    height: 470px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.3s linear 0s;
    overflow: hidden;
    position: relative;
  }
  .news-grids-area   .module:hover {
    box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
  }
  .news-grids-area   .thumbnail {
    position: relative;
    overflow: hidden;
    background: black;
  }
  .news-grids-area  .thumbnail img {
    width: 120%;
    transition: all 0.3s;
  }
  .news-grids-area   .module:hover .thumbnail img {
    transform: scale(1.1);
    opacity: 0.6;
  }
  
  .news-grids-area   .thumbnail .date {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #0166FC;
    padding-top: 10px;
    color: #fff;
    font-weight: bold;
    border-radius: 100%;
    height: 55px;
    width: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
  }
  .news-grids-area   .date div:first-child {
    font-size: 18px;
    line-height: 1.2;
  }
  .news-grids-area   .content {
    position: absolute;
    width: 100%;
    height: 220px;
    bottom: 0;
    background-color: #1d113d;
    padding: 30px;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  }
  .news-grids-area   .module:hover .content {
    height: 278px;
  }
  
  .news-grids-area   .content .category {
    position: absolute;
    top: -34px;
    left: 0;
    color: #fff;
    text-transform: uppercase;
    background: #0166FC;
    padding: 10px 15px;
    font-weight: bold;
  }
  .news-grids-area   .title {
    margin: 0;
    padding: 0 0 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  .news-grids-area   .description {
    color: #989797;
    font-size: 14px;
    line-height: 1.8em;
    height: 0;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
    overflow: hidden;
  }
  .news-grids-area   .module:hover .description {
    height: 100px;
  }

.news-grids-area span{
    font-size: 18px;
    color: #b4b3b3;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 70%;
  }

  .news-grids-area .col-4{
    margin: 1.5rem 0;
  }









  /* news details  */

  .news-details-title h5{
    font-size: 35px;
    line-height: 45px;
    color: #fff;
    padding: 30px 0;

  }
  .news-details-image img{
   width: 100%;

  }
  .news-details-image span{
    font-size: 16px;
    color: #b4b3b3;
    display: block;
    padding: 5px 0;
    width: 100%;
  }
.news-details-dis h5{
  font-size: 20px;
  line-height: 30px;
  color: #969393;
}
  .news-details{
    padding: 20px 0;
    color: #fff;
  }