
.user-loan-area section {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 140px 0;
    background: #262626;
  }
  
  .user-loan-area .loan-card-tem-one .card,
  .user-loan-area .loan-card-tem-one  .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #3806c2, #13092F);
  }
  
  .user-loan-area .loan-card-tem-two  .card,
  .user-loan-area .loan-card-tem-two  .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #13092F, #3806c2);
  }
  
  .user-loan-area .loan-card-tem-three  .card,
  .user-loan-area .loan-card-tem-three  .card .title .loan-plane-icon {
    background: linear-gradient(-45deg, #2c049c, #0B0619);
  }
  
  .user-loan-area .title svg {
    color: #fff;
    font-size: 60px;
    line-height: 100px;
    font-weight: 900;
  }
  
  .loan-plane-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  
  .user-loan-area .title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    color: #fff;
    font-size: 20px;
    z-index: 2;
  }
  
  .user-loan-area .price {
    position: relative;
    z-index: 2;
  }
  
  .user-loan-area .price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 30px;
  }
  
  .user-loan-area .option {
    position: relative;
    z-index: 2;
  }
  
  .user-loan-area .option ul {
    margin: 0;
    padding: 0;
  }
  
  .user-loan-area .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
  }
  

  .user-loan-area .form-control {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: 0.825rem;
    background: #13092F;
    color: #bcd0f7;
    padding: 0.75rem 1rem;
  }
  
  

  .user-loan-area .form-control::placeholder {
    color: #bcd0f7;
  }
  
  .user-loan-area .submit-btn {
    padding: 0.325rem 1rem;
    border: none;
    border-radius: 5px;
    background: #13092F;
    color: #fff;
    font-weight: 600;
    min-width: 140px;
  }
  .user-loan-area .submit-btn:hover {
    transition: 2s;
    background: linear-gradient(-45deg, #3806c2, #13092F);
  }
  
  .user-loan-area .input-group-text {
    background: #13092F;
    color: #bcd0f7;
    border: none;
  }


  .loan-form-section{
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 10px;
  }