.custom-pagination .active>.page-link, .page-link.active {
    color: #fff;
    border-color: rgb(11, 6, 25);
    background: 0% 0% / 300% 300% no-repeat fixed rgb(64 20 188);
}
.custom-pagination .disabled>.page-link, .page-link.disabled {
    color: #8f8d8d;
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    border-color: rgb(11, 6, 25);
}
.custom-pagination .page-link  {
    color: #fff;
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    border-color: rgb(11, 6, 25);
}