.customers-mobile-area {
  margin: auto;
}
.customers-section .resources-content-area {
  padding-left: 0 !important;
}
.customers-mobile {
  border: 1px solid #d1d5db;
  border-radius: 10px;
  max-width: 60%;
  margin: auto;
  padding: 20px;
}
.customers-mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.customers-mobile-body {
  padding-top: 80px;
}
.customers-mobile-payment {
  text-align: center;
  padding-bottom: 20px;
}
.customers-mobile-payment svg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.customers-mobile-payment h5 {
  font-size: 25px;
  line-height: 30px;
  color: #fff;
}
.customers-mobile-payment h5 span {
  font-size: 25px;
  line-height: 30px;
  color: #d1d5db;
  font-weight: 400;
}

.customers-mobile-payment-details {
  padding-bottom: 5px;
}

.customers-mobile-payment-details p {
  font-size: 16px;
  font-weight: 600;
}
.customers-mobile-payment-details span {
  font-size: 16px;
  font-weight: 600;
  color: #d1d5db;
}

.customers-mobile-blocking {
  text-align: center;
  padding: 50px 0 30px 0;
}
.customers-mobile-blocking h4 {
  font-size: 25px;
  line-height: 35px;
  color: #fff;
}

@media (min-width: 850px) and (max-width: 1200px) {
  .customers-section .container {
    width: 95%;
    margin: auto;
  }
  .customers-mobile {
    max-width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 849.9px) {
    .customers-section .resources-section{
        padding-top: 0 !important;
        padding-bottom: 80px;
    }
  .customers-section .row .col-6 {
    width: 100% !important;
  }
  .customers-section .row .col-6:nth-child(1) {
    padding-bottom: 30px;
  }
  .customers-mobile {
    max-width: 100%;
  }
  .customers-mobile-payment-details p {
    font-size: 14px;
  }
  .customers-mobile-payment-details span {
    font-size: 14px;
  }
}
