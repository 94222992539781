.candle-time-framer{
    display: flex;
    align-items: center;
}

.candle-time-framer p{
    background-color: #13092F;
    color: #fff;
    padding: .5vw 1vw;
    margin-right: .5vw;
    border-radius: 5px;
    cursor: pointer;
}
.candle-time-framer .active{
    background-color: rgb(1 102 252 / 1 );
}

.user-live-data-show{
    padding: 1vw 0;
}


.user-live-data-show .user-live-data-symbol h5{
    font-size: 1vw;
    padding-bottom: 5px;
}

.user-live-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-live-data p{
  font-size: .8vw;
  color: #b2b3ba;
}