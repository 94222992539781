.contact-us {
  background-image: url("./bg_image.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 100px 0 80px 0;
}
.contact-us-section .contact-us {
  /* background: #1f2133; */
  font-family: Roboto, sans-serif;
  min-width: 320px;
  overflow: hidden;
  position: relative;
}
.contact-us-section .x-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1070px;
  min-height: calc(100vh - 170px);
  padding-top: 126px;
  position: relative;
  z-index: 2;
}

.contact-us-section .x__width {
  padding: 0 16px;
}

.contact-us-section .x__footer-content,
.contact-us-section .x__navbar-content,
.contact-us-section .x__width {
  box-sizing: initial;
  margin: 0 auto;
  max-width: 1170px;
}

.contact-us-section .x-contact__headings {
  animation: pageload 0.6s ease forwards;
  width: 42%;
}

.contact-us-section .x-contact__headings-subheading {
  color: #fff9;
  display: block;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 4px;
  line-height: 1.67;
}

.contact-us-section .x-contact__headings-heading {
  color: #fff;
  font-size: 80px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  letter-spacing: normal;
  line-height: 80px;
  margin: 20px 0 0 -4px;
  padding-bottom: 64px;
}

.contact-us-section .x-contact__container {
  display: flex;
  justify-content: space-between;
}

.contact-us-section .x-contact__txt {
  animation: pageload 0.6s ease forwards;
  width: 42%;
}

.contact-us-section .x-contact__txt-txt1 {
  border-top: 1px solid #ffffff1a;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.78;
}

.contact-us-section .x-contact__txt-txt1-icon {
  display: block;
  height: 24px;
  margin: 24px 0;
  width: 27px;
}

.contact-us-section .x-contact__txt-txt2 {
  color: #fff9;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.87;
}

.contact-us-section .x-contact__links {
  margin-top: -250px;
  width: 44%;
}

.contact-us-section .x-contact__links-row {
  display: flex;
  width: 100%;
}

.contact-us-section .x-contact__links-item--twitter {
  animation: pageload 0.6s ease forwards;
}

.contact-us-section .x-contact__links-item {
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none !important;
  transform: translateY(100px);
  transition: all 0.15s ease;
  width: 33%;
  z-index: 100;
}

.contact-us-section .x-contact__links-item--twitter > span {
  background: #1da1f2;
  outline: 0 solid #1da1f2;
}

.contact-us-section .x-contact__links-item-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-bottom: calc(50% - 30px);
  padding-top: calc(50% - 30px);
  position: relative;
  transition: all 0.18s ease;
  width: 100%;
}

.contact-us-section .x-contact__links-item-container:before {
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.18s ease;
  width: 100%;
}

.contact-us-section .x-contact__links-item svg {
  margin-bottom: 24px;
  font-size: 32px;
}

.contact-us-section .x-contact__links-item-label {
  height: 15px;
}

.contact-us-section .x-contact__links-item--facebook {
  animation: pageload 0.6s ease 0.4s forwards;
  margin-left: 33%;
}

.contact-us-section .x-contact__links-item--facebook > span {
  background: #3b5998;
  outline: 0 solid #3b5998;
}

.contact-us-section .x-contact__links-row {
  display: flex;
  width: 100%;
}

.contact-us-section .x-contact__links-item--email {
  animation: pageload 0.6s ease 0.2s forwards;
  margin-left: 33%;
}

.contact-us-section .x-contact__links-item--email > span {
  background: #531aff;
  outline: 0 solid #531aff;
}

.x-page-contact .x-contact__links-item:hover {
  z-index: 150;
}

.x-page-contact .x-contact__links-item--twitter {
  animation: pageload 0.6s ease forwards;
}

.x-page-contact .x-contact__links-item--twitter:hover > span {
  background: #0f9bf1;
  outline: 10px solid #0f9bf1;
}

@media (min-width: 0px) and (max-width: 980px) {
  .contact-us-section .contact-us {
    padding: 80px 0;
  }
  .contact-us-section .x-contact__container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
  .contact-us-section .x-contact__links {
    margin-top: 0;
    width: 80%;
    margin: auto;
    padding: 50px 0;
  }
  .contact-us-section .x-contact__headings {
    width: 95%;
    text-align: center;
  }
  .contact-us-section .x-contact__txt {
    width: 95%;
    margin: auto;
    text-align: center;
  }
  .contact-us-section .x-contact__headings-heading {
    margin: 0;
    padding-bottom: 35px;
  }
  .contact-us-section .x-contact__txt-txt1-icon {
    margin: auto;
  }
  .x-contact__txt-txt1 {
    padding-top: 20px;
  }
  .contact-us-section .x-contact__links-item-container {
    padding: 20px;
    margin: auto;
  }
  .contact-us-section .x-contact__links-item {
    width: 100%;
  }
  .contact-us-section .x-contact__links-item--email {
    margin: auto;
  }

  .contact-us-section .x-contact__headings-heading {
    font-size: 50px;
    line-height: 65px;
  }
  .contact-us-section .x-contact__links-row {
    display: block;
  }
  .contact-us-section .x-contact__links-item--facebook {
    margin-left: 0;
  }
}
