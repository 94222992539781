.trading-platfrom-section {
  overflow: hidden;
  padding-top: 65px;
}

#advs .ttl {
  margin-bottom: 50px;
}

.ttl {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 20px;
  width: 100%;
}

.centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.ttl span {
  color: #28d697;
}

.adv-items {
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex {
  display: flex;
}

.adv-item {
  width: 25%;
  padding: 35px 50px;
  background: #fff;
  color: #000;
  margin: 0 15px 70px;
  height: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
}

.adv-item .icon {
  background: -webkit-linear-gradient(
    -45deg,
    rgb(23, 220, 208) 0%,
    rgb(40, 214, 151) 100%
  );
  left: -30px;
  top: 0;
  margin: auto;
  bottom: 0;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transform: rotate(45deg);
}

.adv-item .icon:before {
  content: "";
  background-image: url("./check.png");
  width: 23px;
  height: 23px;
  transform: rotate(-45deg);
  background-size: contain;
  background-repeat: no-repeat;
}

.adv-item .txt {
  line-height: 1.6;
  font-size: 16px;
}

@media (min-width: 600px) and (max-width: 1000px) {
  
    .trading-platfrom-section .container{
        max-width: 95% !important;
        margin: auto !important; 
    }
  .trading-platfrom-section .flex {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .trading-platfrom-section .adv-item {
    width: 80%;
  }
  .more-control-sectiona #transf:after{
    width: 25px;
  }
  .ttl {
    font-size: 30px;
  }
}

@media (min-width: 0px) and (max-width: 599.9px) {
   
    .trading-platfrom-section .container{
        max-width: 85% !important;
        margin: auto !important; 
    }
  .trading-platfrom-section .flex {
    display: grid !important;
    justify-items: center;
  }
  .trading-platfrom-section .adv-item {
    width: 95%;
  }
  .more-control-sectiona #transf:after{
    width: 25px;
  }

  .ttl {
    font-size: 25px;
  }

}
