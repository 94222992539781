.benefits-content-area{
    padding: 50px 0;
}
.benefits-content-area ul li{
    font-size: 20px;
    line-height: 30px;
    color: #CECDD1;
    padding-bottom: 15px;
}
.benefits-content-area ul li:nth-child(even){
   font-style: oblique;
   color: #fff;
}




.benefits-content-area ol, ul {
    padding-left: 0 !important;
  }








/* //////////////////////////////////////////// */

.benefits-gold-investment-services h5{
    font-size: 25px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 20px
}

    
 
.benefits-gold-investment-services p{
    font-size: 18px;
    color: #D1D5DB;
    font-weight: 600;
    padding-bottom: 20px
}

.benefits-gold-investment-services-list li{
    font-size: 20px !important;
    line-height: 30px  !important;
    font-weight: 500  !important;
    color: #fff  !important;
}
    
.benefits-gold-investment-services-list p{
    font-size: 18px !important;
    line-height: 30px  !important;
    font-weight: 400  !important;
    color: #D1D5DB  !important;
}
    
 
 

@media (min-width: 0px) and (max-width: 767.9px) {
.benefits-section h4{
    font-size: 30px ;
    width: 95%;
    margin: auto;
}
}