


/* Chat css New ////////////////////// */

.chat-section-area .row {
    --bs-gutter-x: 0 !important;
  
  }
  .chat-section{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
 
  .chat-section-area {
    background-color: var(--background);
    border-radius: 5px;
    width: 30%;

    /* box-shadow: rgba(113, 113, 113, 0.56) 0px 22px 70px 4px; */
  }
  .chat-section-area input::placeholder{
    color: #a09e9e;
  }
  .box {
    position: relative;
    border-radius: 3px;
    background: var(--background);
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  }
  
  
  .box.box-success {
    border-top-color: #00a65a;
  }
  
  .box.box-default {
    border-top-color: #d2d6de;
  }
  
  .box.collapsed-box .box-body,
  .box.collapsed-box .box-footer {
    display: none;
  }
  
  .box .nav-stacked>li {
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
  }
  
  .box .nav-stacked>li:last-of-type {
    border-bottom: none;
  }
  
  .box.height-control .box-body {
    max-height: 300px;
    overflow: auto;
  }
  
  .box .border-right {
    border-right: 1px solid #f4f4f4;
  }
  
  .box .border-left {
    border-left: 1px solid #f4f4f4;
  }
  
  .box.box-solid {
    border-top: 0;
  }
  
  .box.box-solid>.box-header .btn.btn-default {
    background: transparent;
  }
  
  .box.box-solid>.box-header .btn:hover,
  .box.box-solid>.box-header a:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .box.box-solid.box-success {
    border: 1px solid #00a65a;
  }
  
  .box.box-solid.box-success>.box-header {
    color: #fff;
    background: #00a65a;
    background-color: #00a65a;
  }
  
  .box.box-solid.box-success>.box-header a,
  .box.box-solid.box-success>.box-header .btn {
    color: #fff;
  }
  
  .box.box-solid>.box-header>.box-tools .btn {
    border: 0;
    box-shadow: none;
  }
  
  .box.box-solid[class*='bg']>.box-header {
    color: #fff;
  }
  
  .box .box-group>.box {
    margin-bottom: 5px;
  }
  
  .box .knob-label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0.3em;
  }
  
  .box>.overlay,
  .overlay-wrapper>.overlay,
  .box>.loading-img,
  .overlay-wrapper>.loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
  
  .box .overlay,
  .overlay-wrapper .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }
  
  .box .overlay>.fa,
  .overlay-wrapper .overlay>.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px;
  }
  
  .box .overlay.dark,
  .overlay-wrapper .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .box-header:before,
  .box-body:before,
  .box-footer:before,
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    content: " ";
    display: table;
  }
  
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    clear: both;
  }
  
  .box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
  }
  
  .box-header.with-border {
    border-bottom: 1px solid #13092f;
  }
  
  .collapsed-box .box-header.with-border {
    border-bottom: none;
  }
  
  .box-header>.fa,
  .box-header>.glyphicon,
  .box-header>.ion,
  .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
  
  .box-header>.fa,
  .box-header>.glyphicon,
  .box-header>.ion {
    margin-right: 5px;
  }
  
  .box-header>.box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
  }
  
  .box-header>.box-tools [data-toggle="tooltip"] {
    position: relative;
  }
  
  .box-header>.box-tools.pull-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  
  .btn-box-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #97a0b3;
  }
  
  .open .btn-box-tool,
  .btn-box-tool:hover {
    color: #606c84;
  }
  
  .btn-box-tool.btn:active {
    box-shadow: none;
  }
  
  .box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
  }
  
  .no-header .box-body {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  
  .box-body>.table {
    margin-bottom: 0;
  }
  
  .box-body .fc {
    margin-top: 5px;
  }
  
  .box-body .full-width-chart {
    margin: -19px;
  }
  
  .box-body.no-padding .full-width-chart {
    margin: -9px;
  }
  
  .box-body .box-pane {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
  }
  
  .box-body .box-pane-right {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  
  .box-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #13092f;
    padding: 10px;
    background-color: var(--background);
  }
  
  .direct-chat .box-body {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    overflow-x: hidden;
    padding: 0;
  }
  
  .direct-chat-messages {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 10px;
    height: 70vh;
    overflow: auto;
    scrollbar-color: var(--background) var(--black);
  }
  
  .direct-chat-msg,
  .direct-chat-text {
    display: block;
  }
  
  .direct-chat-msg {
    margin-bottom: 10px;
  }
  
  .direct-chat-msg:before,
  .direct-chat-msg:after {
    content: " ";
    display: table;
  }
  
  .direct-chat-msg:after {
    clear: both;
  }
  
  .direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 5px 10px;
    background: #d2d6de;
    border: 1px solid #d2d6de;
    margin: 5px 0 0 50px;
    color: #444;
  }
  
  .direct-chat-text:after,
  .direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #d2d6de;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
  }
  
  .direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
  }
  
  .direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
  }
  
  .right .direct-chat-text {
    margin-right: 50px;
    margin-left: 0;
  }
  
  .right .direct-chat-text:after,
  .right .direct-chat-text:before {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: #d2d6de;
  }
  
  .direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 40px;
    height: 40px;
  }
  
  .right .direct-chat-img {
    float: right;
  }
  
  .direct-chat-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    font-size: 12px;
  }
  
  .direct-chat-name {
    font-weight: 600;
  }
  
  .direct-chat-timestamp {
    color: #999;
  }
  
  .contacts-list>li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 0;
  }
  
  .contacts-list>li:before,
  .contacts-list>li:after {
    content: " ";
    display: table;
  }
  
  .contacts-list>li:after {
    clear: both;
  }
  
  .contacts-list>li:last-of-type {
    border-bottom: none;
  }
  
  .contacts-list-img {
    border-radius: 50%;
    width: 40px;
    float: left;
  }
  
  .contacts-list-info {
    margin-left: 45px;
    color: #fff;
  }
  
  .contacts-list-name,
  .contacts-list-status {
    display: block;
  }
  
  .contacts-list-name {
    font-weight: 600;
  }
  
  .contacts-list-status {
    font-size: 12px;
  }
  
  .contacts-list-date {
    color: #aaa;
    font-weight: normal;
  }
  
  .contacts-list-msg {
    color: #999;
  }
  
  .direct-chat-success .right>.direct-chat-text {
    background: #00a65a;
    border-color: #00a65a;
    color: #fff;
  }
  
  .direct-chat-success .right>.direct-chat-text:after,
  .direct-chat-success .right>.direct-chat-text:before {
    border-left-color: #00a65a;
  }
  
  
  
  .browse {
    display: inline-block;
    position: relative;
  }
  
  .browse-file {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    text-align: right;
    opacity: 0;
    z-index: 2;
  }
  
  .browse-file-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  /* .browse-file-img svg{
    font-size: 30px;
  } */
  
  .direct-chat-messages{
    color: #fff !important;
  }

  

  
/* Live chat css  //////////////////////////////////////////////////////////*/
.card-bordered {
  border: 1px solid #ebebeb;
}



.padding {
  padding: 3rem !important
}


.btn-secondary {
  color: #4d5259 !important;
  background-color: #e4e7ea;
  border-color: #e4e7ea;
  color: #fff;
}

.btn-xs {
  font-size: 11px;
  padding: 2px 8px;
  line-height: 18px;
}

.btn-xs:hover {
  color: #fff !important;
}






.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
  width: 100% !important;
}

.media-body {
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight: 100;
  color: #9b9b9b;
}

.media>* {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: .8;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: .8;
  font-weight: 400;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before,
.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.media-chat.media-chat-reverse .media-body p {
  float: right;
  clear: right;
  background-color: #48b0f7;
  color: #fff;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
}

.chat-file-upload .filelabel {
  border: 2px solid grey;
  border-radius: 5px;
  display: block;
  padding: 7px 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}

.chat-file-upload .filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}

.chat-file-upload .filelabel i,
.chat-file-upload .filelabel {
  color: grey;
  transition: 200ms color;
}

.chat-file-upload .filelabel:hover {
  border: 2px solid #1665c4;
}

.chat-file-upload .filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}

#FileInput {
  display: none;
}

.chat-file-btn {
  font-size: 12px;
  padding: 6px 7px;
}

.border-light {
  border-color: #f1f2f3 !important;
}

.chat-attach-file {
  padding: 1px 2px;
  color: red;
  display: inline-block;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9fafb;
}

.publisher>*:first-child {
  margin-left: 0;
}

.publisher>* {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  outline: none !important;
  background-color: #686363;
  height: 50px;
  padding: 20px 10px;
  color: #fff;
  border-radius: 10px;
}

button,
input,
optgroup,
select,
textarea {
  background-color: var(--background);
  color: var(--text-primary);
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group {
  position: relative;
  overflow: hidden;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: .2s linear;
  transition: .2s linear;
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: #48b0f7 !important;
}

/* Live chat css  End//////////////////////////////////////////////////////////*/
