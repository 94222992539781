.about-title {
  background-image: url("./single_faint.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  padding: 100px 0 50px 0;
  text-align: center;
}

.about-title h4 {
  font-size: 45px;
  line-height: 45px;
  color: #fff;
}
.about-title p {
  font-size: 20px;
  padding-top: 5px;
  color: #cecdd1;
}

.about-main {
  padding: 80px 0;
}

.about-first-section p {
  font-size: 20px;
  line-height: 30px;
  color: #cecdd1;
}
.about-first-section-image {
  padding: 50px 0;
}
.about-first-section-image .row {
  padding-bottom: 20px;
}
.about-first-section-image img {
  width: 90%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 10px;
}

.about-first-section-image p {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 10px 0 20px 0;
  align-items: center;
  display: flex;
  height: 100%;
}

.about-first-section-image span {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-style: oblique;
}

/* .about-main p{
   font-size: 20px;
   line-height: 30px;
   color: #CECDD1;
   padding-bottom: 30px;
}
.about-main p:nth-child(2){
   font-style: oblique;
}
.about-main p:nth-child(3){
   font-weight: 600;
}
.about-main p:nth-child(5){
   color: #fff;
   font-style: italic;
} */

/* //////////////////////////////////////////////////////////////////////////////// */

.our-target .home-main .about {
  padding: 80px 0;
}

.our-target .bg-1 {
  background: #13092f;
  padding: 45px 0;
}

.our-target .home-main .about .about__content {
  padding-top: 89px;
}

.our-target .about.style-2 .about__content {
  position: relative;
  background: url("https://swoopventures.com/model_home/assets/images/background/bg-features.png")
    no-repeat;
  background-size: contain;
  background-position: center center;
  padding: 80px 58px 60px;
}

.our-target .about.style-2 .about__content .block-text h3 {
  font-family: "Zen Dots";
  font-size: 36px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-transform: capitalize;
}

.our-target .about.style-2 .about__content .block-text p {
  margin-top: 30px;
  margin-right: -30px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  font-weight: 500;
}

.our-target .about.style-2 .about__content .tf-box .box-icon {
  background: url("https://swoopventures.com/model_home/assets/images/background/bg-iconbox.png")
    no-repeat;
  background-position: center center;
  padding: 27px 20px;
  text-align: center;
  margin-left: 49px;
  cursor: pointer;
  width: 180px;
  height: 176px;
  transition: 0.5s all ease-in-out;
}
.our-target .about.style-2 .about__content .tf-box {
  display: flex;
  justify-content: flex-end;
}
.our-target .about.style-2 .about__content .tf-box .box-icon span {
  font-size: 54px;
  color: var(--primary-color);
  transition: 0.3s all ease-in-out;
  display: block;
  color: #c004de;
}

/* .icon-buy:before {
   content: "\e919";
} */
.our-target .about.style-2 .about__content .tf-box .box-icon p {
  margin-bottom: 0;
  color: #f6f6f6;
  font-weight: 500;
}

/* .icon-create:before {
   content: "\e918";
} */

/* .icon-chase:before {
   content: "\f630";
} */

.our-target .icon-buy:hover {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
.our-target .icon-chase:hover {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
.our-target .icon-create:hover {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

/* ///////////////////////////////////////////////////////////// */

.share-market-section {
  padding: 50px 0;
}
.share-market-section img {
  width: 95%;
  height: auto;
}
.share-market-section h5 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  line-height: 35px;
  padding-bottom: 10px;
}

.share-market-section p {
  font-size: 18px;
  font-weight: 400;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  line-height: 30px;
  padding-bottom: 10px;
}

/* /////////////////////////////////////////////////// */

.what-is-rubicon-section {
  padding: 80px 0;
  background-color: #13092f;
}

.what-is-rubicon-section .about__image img {
  animation: move5 3s infinite linear;
}

@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.what-is-rubicon-section .about__content .title {
  margin-bottom: 12px;
  font-size: 36px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-transform: capitalize;
}

p {
  color: #f6f6f6;
  font-family: "Inter", sans-serif;
}

.what-is-rubicon-section .about__content h5 {
  margin-bottom: 12px;
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-transform: capitalize;
}

.what-is-rubicon-section .icon-box .content p {
  line-height: 1.6;
  color: #f6f6f6;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}
.what-is-rubicon-section .list-icon-box {
  margin-top: 41px;
  position: relative;
}
.what-is-rubicon-section .list-icon-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  left: 24px;
  width: 2px;
  height: 80%;
  border-left: 2px dashed #4388dd;
}

.what-is-rubicon-section .icon-box {
  display: flex;
  margin-bottom: 18px;
  position: relative;
  z-index: 1;
}

.what-is-rubicon-section .icon-box svg {
  width: 20px;
  height: 20px;
  margin-right: 30px;
  padding: 15px;
  border-radius: 50%;
  color: #fff;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
}

/* //////////////////////////////////////////////////// */

.business-section {
  padding: 81px 0 76px;
  /* background: #130F40; */
}

.business-section .center {
  text-align: center;
}

.business-section .block-text .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  color: #fff;
}

.business-section .choose__box {
  margin-top: 39px;
}

.business-section .choose__box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.business-section .choose__box .choose__box-icon .icon {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
}

.business-section .choose__box-icon .icon {
  min-width: 66px;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: linear-gradient(294.72deg, #ff4581 9.05%, #4388dd 79.28%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.7s all ease-in-out;
  margin-bottom: 47px;
}

.business-section .choose__box .choose__box-icon .icon span {
  font-size: 34px;
}
.business-section .content a {
  font-size: 22px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  margin-bottom: 0.5rem;
}
.business-section .content p {
  color: #fff;
  margin-top: 15px !important;
  margin-bottom: 0;
  font-size: 17px;
}

.business-section svg {
  color: #48d6d2;
}

/* ///////////////////////////////////////////////////// */

.restricted-area-section {
  padding: 20px 0;
  background-color: #13092f;
}

.restricted-area-title svg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  background-color: red;
}

.restricted-area-title h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 22px;
  padding: 10px 0;
}

.restricted-area-title p {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 15px;
}
.restricted-area-content p {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
  line-height: 15px;
}

.restricted-area-content h5 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 22px;
  padding: 10px 0;
}
/* .restricted-area-section  ol {
  padding-left: 0;
  list-style: none;
  counter-reset: ordered-list;
} */
.restricted-area-section li {
  /* padding: 8px 8px 8px 0; */
  transition: ease 400ms;
  cursor: pointer;
  display: flex;
  letter-spacing: 1px;
  opacity: 0.85;
  color: #fff;
  font-size: 12px;
}

.restricted-area-section li:hover {
  transform: translateX(20px);
  opacity: 1;
}

.restricted-area-section li:focus-visible {
  outline: 2px solid red;
  outline-offset: 2px;
  border-radius: 4px;
}

.restricted-area-section li:before {
  /* counter-increment: ordered-list; */
  /* content: counter(ordered-list); */
  font-family: "Sharpie", sans-serif;
  margin-right: 10px;
  background-color: #ff4e4e;
  color: #f6f6f6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

@media (min-width: 981px) and (max-width: 1199.9px) {
}

@media only screen and (max-width: 1200px) {
  .our-target .about.style-2 .about__content {
    background-size: cover;
  }
  .our-target .container {
    max-width: 100%;
  }

  .our-target .about.style-2 .about__content .tf-box .box-icon {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .our-target .about.style-2 .about__content .tf-box {
    margin-top: 30px;
  }
  .our-target .about.style-2 .about__content .tf-box {
    justify-content: center;
  }

  .our-target .about.style-2 .about__content .tf-box {
    flex-wrap: wrap;
    justify-content: center;
  }

  .our-target .about.style-2 .about__content .tf-box {
    margin-top: 30px;
  }
}
@media (min-width: 1200.1px) and (max-width: 1400px) {
  .our-target .about.style-2 .about__content .tf-box .box-icon {
    margin-left: 0;
  }
}
@media (min-width: 0px) and (max-width: 980px) {
  .share-market-section .row .col-6 {
    width: 100%;
  }
  .share-market-section .row .col-6 img {
    padding-bottom: 30px;
  }
}
@media (min-width: 0px) and (max-width: 770px) {
  .about-first-section-image .row .col-6 {
    width: 100%;
  }
  .about-first-section-image .row .col-6 img {
    padding-bottom: 30px;
  }
}
@media (min-width: 0px) and (max-width: 600px) {
  .about-title h4 {
    font-size: 30px;
    line-height: 40px;
  }
  .about-main {
    padding: 40px 0 0 0;
  }
  .what-is-rubicon-section .about__content .title {
    font-size: 30px;
  }
  .business-section .block-text .title {
    font-size: 30px;
  }
  .restricted-area-title {
    text-align: center;
  }
  .restricted-area-section .row .col-6 {
    width: 100%;
  }

  .restricted-area-title h5 {
    font-size: 16px;
    padding: 0;
}
.restricted-area-content h5 {
  font-size: 14px;
  line-height: 18px;
  padding: 0;
}
.restricted-area-content{
  text-align: center;
}
.share-market-section h5{
  text-align: center;
}

.our-target .about.style-2 .about__content {
  padding: 55px 0px 35px;
  text-align: center;
}
.our-target .about.style-2 .about__content .block-text p {
  margin-right: 0;

}
.about-section{
  text-align: center;
}
}
