.BlurBackground___StyledDiv-sc-8xdmpl-0 {
  background-image: url("./double_short.png") !important;
  background-repeat: no-repeat;
  z-index: -1;
}
.stQFp {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}
.stQFp {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

@media (min-width: 1024px) {
  .kxDAN {
    max-width: 1280px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 8rem;
  }
}

.kxDAN {
  margin-left: auto;
  margin-right: auto;
  padding: 7rem 0.5rem 2rem;
}

@media (min-width: 640px) {
  .gfrrKP {
    margin-bottom: 0px;
    text-align: center;
  }
}

.gfrrKP {
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
}

.hdhemy {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 9999px;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #ef1b13 var(--tw-gradient-from-position);
  --tw-gradient-to: #5200ff var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from),
    #ffa800 var(--tw-gradient-via-position), var(--tw-gradient-to);
  padding: 0.125rem;
}

.dBBftA {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(15 9 32 / var(--tw-bg-opacity));
  padding: 0.75rem 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hCVRhG {
  margin: 1.5rem auto 2.5rem;
  max-width: 56rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .kiLjyu {
    font-size: 5rem;
    line-height: 110%;
  }
}

.kiLjyu {
  margin-bottom: 1.5rem;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-weight: 700;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .bwAAvw {
    width: 100%;
  }
}

.bwAAvw {
  margin-left: auto;
  margin-right: auto;
  min-height: 48px;
  /* width: 328px; */
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hMQuxx {
  min-height: 48px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.bXIhIs {
  margin-left: auto;
  margin-right: auto;
}

.gvOYXD {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 9999px;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(238 244 255 / var(--tw-bg-opacity));
  padding: 1.25rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(11 6 25 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.gvOYXD:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .Header___StyledLightningBoltIcon-sc-yzxm8k-15 {
  fill: currentcolor;
}

.eDRmim {
  margin-left: 0.5rem;
  height: 1.25rem;
  width: 1.25rem;
  fill: rgb(239, 68, 68);
}

@media (min-width: 0px) and (max-width: 980px) {
  .kxDAN {
    padding: 100px 0.5rem 2rem;
    text-align: center;
}

  .kiLjyu {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 5px;
  }
 
  .gfrrKP {
    display: block;
  }
  .bXIhIs {
    text-align: center;
  }
  .gvOYXD {
    font-size: 12px;
    padding: 10px 20px;
  }
  .hCVRhG {
    margin: 1.5rem auto 1rem;
  }
  .financial-exchange-silder {
    font-size: 14px;
  }


  


}

@media (min-width: 550px) and (max-width: 1025px) {
  .kiLjyu {
    font-size: 50px;
    line-height: 65px;
  }
  
}