footer.footer-wrapper {
  /* background-color: #181a20; */
  padding: 80px 24px 50px;
  text-align: center;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 24px;
  /* width: 1200px; */
  justify-content: space-between;
  margin: 0 auto;
}

.footer-navlist-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #fff;
}
.footer-icongroup {
  margin-top: 24px;
  
}
.footer-icongroup a{
  padding:  0 10px;
  
}
.footer-icongroup svg {
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #eaecef;
}

.footer-icongroup svg:hover {
  color: #b7bdc6;
}

.footer-navlist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
}

.footer-navlist-sub {
  break-inside: avoid;
}

.footer-navlist-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.footer-navlist-expand-icon {
  display: none;
}

.footer-navlist .footer-navlist-item-list {
  list-style: none;
  margin-top: 16px;
}

.footer-navlist .footer-navlist-item .color-textprimary {
  color: #eaecef;
}

footer a {
  display: inline;
}

@media only screen and (min-width: 768px) {
  .t-Body3,
  .typography-Body3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.footer-wrapper ul {
  padding: 0;
}

.footer-navlist .footer-navlist-item .color-textprimary:hover {
  color: #f0b90b;
}

.footer-navlist-item + .footer-navlist-item {
  margin-top: 8px;
}

.footer-copyright {
  max-width: 1200px;
  margin: 35px auto 0;
}

.footer-copyright-line {
  width: 100%;
  height: 1px;
  background-color: #2b3139;
}

.footer-copyright-text {
  margin-top: 16px;
  color: #b7bdc6;
}

.no-wrap {
  white-space: nowrap;
}

.footer-copyright-cookieprefrence {
  cursor: pointer;
  margin-inline-start: 24px;
  white-space: nowrap;
}


@media (min-width: 0px) and (max-width: 750px) {
  .footer-content {
    display: block;
    text-align: center;
  
}
  .footer-navlist {
    display: inline-block;
  }
  .footer-icongroup{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
  }
}