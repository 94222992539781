.x-why-work {
  /* background: #f5f5f7; */
  overflow: hidden;
  padding-top: 60px;
}

.x__width {
  padding: 0 16px;
}

.x__footer-content,
.x__navbar-content,
.x__width {
  box-sizing: initial;
  margin: 0 auto;
}

.x-why-work__section1 div:first-child {
  padding-left: 0;
}

.x-h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0 0 16px;
  padding: 0;
}

.x-why-work__txt {
  color: #cecdd1;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  opacity: 0.6;
}

.x-p {
  color: #cecdd1;
  font-size: 15px;
  line-height: 1.87;
  margin: 24px 0 0;
  opacity: 0.6;
  padding: 0;
}

.x-why-work__img {
  width: 120%;
}

.x-why-work__features {
  padding: 84px 16px 130px;
}

.x .carousel-inner {
  width: auto;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.x-why-work__features-item:first-of-type svg {
  box-shadow: 0 8px 18px 0 #467df440;
}

.x-why-work__features-item svg {
  border-radius: 50%;
  height: 64px;
  margin: 0 0 48px;
  width: 64px;
}

.x-why-work__features h3 {
  color: #1f2033;
  font-size: 18px;
  font-weight: 400;
}

.x-why-work__features h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.jobs-header-area {
  padding-top: 50px;
  margin: auto;
}
.jobs-header {
  width: 50%;
}

.jobs-header span {
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 4px;
  line-height: 1.67;
  opacity: 0.6;
  text-transform: uppercase;
}
.jobs-header h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  margin: 20px 0 60px;
  padding-top: 0;
  padding-top: 10px;
}

.jobs-header p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.25;
  opacity: 0.6;
  color: #cecdd1;
}

/* ///////////////////////// */

.sophisticated-institutions img {
  width: 100%;
  height: auto;
}
.sophisticated-institutions .content-area {
  padding-left: 30px;
}
.sophisticated-institutions .content-area h4 {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  padding-bottom: 15px;
}
.sophisticated-institutions .content-area p {
  font-size: 18px;
  line-height: 25px;
  color: #d1d5db;
}
.sophisticated-institutions .content-area .learn-More {
  padding-top: 40px;
}
.sophisticated-institutions .content-area .learn-More a {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: #fff;
  box-shadow: 0px 0px 14px -7px #28165a;
  background-image: linear-gradient(
    45deg,
    #5c38c1 0%,
    #1b0f3c 51%,
    #5c38c1 100%
  );
  border-radius: 10px;
  padding: 10px 30px;
  background-size: 200% auto;
}

/* ////////////////////////////////// */

.resources-section {
  padding: 80px 0;
}

.resources-section {
  width: 100%;
  height: auto;
}

.resources-content-area {
  padding-left: 30px;
  display: flex;
  align-items: center;
  height: 100%;
}

.resources-content-area h4 {
  font-size: 35px;
  line-height: 45px;
  color: #fff;
  padding-bottom: 15px;
  font-weight: 700;
}

.resources-content-area p {
  font-size: 20px;
  line-height: 30px;
  color: #d1d5db;
  padding-bottom: 15px;
}
.resources-content-area .resources-content-dis {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.resources-content-area .resources-content-dis svg {
  width: 25px;
  height: 25px;
  background-color: #fff;
  color: #1e1142;
  border-radius: 50%;
  margin-right: 15px;
}
.resources-content-area .resources-content-dis span {
  font-size: 18px;
  line-height: 25px;
  color: #d1d5db;
  font-weight: 500;
}

@media (min-width: 850px) and (max-width: 991px) {
  .jobs-header {
    width: 70%;
  }
  .x-h2 {
    font-size: 35px;
    line-height: 46px;
  }
  .x-why-work__txt {
    font-size: 24px;
  }
}
@media (min-width: 768px) and (max-width: 849.9px) {
  .jobs-header {
    width: 80%;
    margin: auto;
  }
  .x-why-work .row .col-md-5 {
    width: 100%;
    margin: auto;
  }
  .x-why-work .row .col-md-7 {
    width: 100%;
    margin: auto;
    padding-top: 10px;
  }
  .x-why-work__features .row .col-md-3 {
    width: 50%;
    padding-bottom: 30px;
  }
  .x-why-work__features {
    padding: 40px 0;
  }

  .x-why-work__features-item svg {
    margin: 0 0 20px;
  }

  .sophisticated-institutions .row .col-6 {
    width: 100%;
    padding-bottom: 30px;
  }
  .sophisticated-institutions .content-area {
    padding-left: 0;
  }
  .resources-section .row .col-6:nth-child(1){
    width: 30%;
  }
  .resources-section .row .col-6:nth-child(2){
    width: 70%;
  }
  .resources-section .row .col-6 img{
    height: 200px;
    width: auto;
  }
}

@media (min-width: 0px) and (max-width: 767.9px) {
  .jobs-header {
    width: 100%;
    text-align: center;
}
.jobs-header h3 {
  font-size: 25px;
  line-height: 30px;
}
.jobs-header p {
  font-size: 18px;
}


.job-section .contact-us {
  padding: 50px 0 40px 0;
}
.jobs-header-area {
  padding-top: 50px;
}
.x-why-work{
  text-align: center;
}
.x-h2 {
  font-size: 25px;
}
.x-why-work__txt {
  font-size: 18px;
}
.x-why-work__img {
  padding-top: 20px;
}
.x-why-work__features svg{
  margin: auto;
}
.x-why-work__features-item{
  min-height: auto !important;
  padding-bottom: 40px;
}
.x-p {
  margin: 10px 0 0;
}

.x-why-work__features {
  padding: 45px 0 0;
}

.x-why-work__features h3 {
 padding-top: 15px;
}
.sophisticated-institutions{
  text-align: center;
}
.sophisticated-institutions .row .col-6{
  width: 100%;
}
 
.sophisticated-institutions .row .col-6 img{
  padding-bottom: 30px;
}

.sophisticated-institutions .content-area {
  padding-left: 0;
}
.sophisticated-institutions .content-area h4 {
  font-size: 25px;
  line-height: 30px;
}
.resources-section {
  text-align: center;
}
.resources-section .row .col-6{
  width: 100%;
}
.resources-content-area h4 {
  font-size: 25px;
  line-height: 30px;
  padding-top: 30px;
}

.resources-content-area {
  padding-left: 0;
}
.resources-content-area p {
  font-size: 18px;
  line-height: 25px;
}
.resources-content-area .resources-content-dis span {
  font-size: 16px;
  line-height: 22px;
}

.resources-section {
  padding: 45px 0 0 0;
}

}