.our-mession-section .jvMkpH {
  /* position: relative; */
  padding-top: 30px;
  padding-bottom: 85px;
}


.our-mession-section .cguWgh {

  margin: auto;
  width: 100%;
}

.our-mession-section .hLiJtT {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-width: 1024px) {
  .our-mession-section .jFkHiP {
    font-size: 3.75rem !important;
  }
}

@media (min-width: 768px) {
  .our-mession-section  .jFkHiP {
    font-size: 52px ;
  }
}

 .jFkHiP {
  margin-bottom: 1rem;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 2.75rem;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.our-mession-section .crCcLP {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 5px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.our-mession-section span {
  display: block;
  padding-top: 15px;
  margin: auto;
  width: 50%;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: 18px;
  line-height: 1.5rem;
  font-weight: 400;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 0) and (max-width: 980px) {
  .our-mession-section span{
    width: 100%;
    font-size: 16px;
  }
  .jFkHiP {
    font-size: 40px ;
  }
}

@media (min-width: 0) and (max-width: 600px) {
  .jFkHiP {
    font-size: 25px;
    line-height: 40px;
    width: 90%;
    margin: auto;
    padding-bottom: 10px;
  }

  .our-mession-section .crCcLP {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .our-mession-section span{
    padding-left: 15px;
    padding-right: 15px;
  }
}