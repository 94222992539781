.assets{
    padding-bottom: 20px;
}
.assets-card {
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: 12px;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  overflow: hidden;
  box-shadow: 5px 5px 20px;
}

.assets-wave {
  position: absolute;
  width: 500px;
  height: 700px;
  opacity: 0.6;
  right: -35%;
  top: 10px;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(28 7 87);
  border-radius: 40%;
  animation: assets-wave 5s infinite linear;
}

.assets-wave:nth-child(2) {
  top: 40px;
  animation: assets-wave 7s infinite linear;
}
.assets-wave:nth-child(3) {
  top: 45px;
  animation: assets-wave 9s infinite linear;
}

@keyframes assets-wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.assets-content {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 100%;
}
.assets-balance-area {
  display: flex;
  align-items: center;
}
.assets-balance-area h5 {
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  padding-right: 15px;
}
.assets-balance-area span {
  font-size: 18px;
  line-height: 25px;
  color: #a5a3a3;
}

.assets-coin-title-area {
  text-align: center;
  padding: 20px 0;
}

.assets-coin-title-area h6 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
}

.assets-coin-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  height: 100%;
}
.assets-coin {
  display: flex;
  align-items: center;
}
.assets-coin img {
  width: 70px;
  height: 70px;
  padding-right: 20px;
}
.assets-coin-name h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: #fff;
}
.assets-coin-name p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #d4d1d1;
}
.assets-coin-name span {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #0166fc;
}
.assets-coin-percentage p {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  padding: 5px 15px;
  border: 2px solid #fff;
  border-radius: 20px;
}
