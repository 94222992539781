.bottom-cta.dark {
    background-image: url("./single_faint.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.py-\[64px\] {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
}

.mb-\[40px\] {
    margin-bottom: 40px !important;
}

@media only screen and (min-width: 768px) {
    .bn-button.data-size-large {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
    }
}
.bn-button.data-size-large {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    line-height: 24px;
    min-height: 48px;
    min-width: 80px;
    padding-left: var(--space-m);
    padding-right: var(--space-m);
}

.text-\[16px\] {
    font-size: 16px !important;
}

.py-2xs {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
}

.px-m {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.min-w-\[164px\] {
    min-width: 164px !important;
}

.h-\[48px\] {
    height: 48px !important;
}

@media only screen and (min-width: 768px) {
    .bn-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
}

.bn-button {
    grid-gap: var(--space-3xs);
    --button-bg: #FCD535;
    --button-color: var(--color-TextOnYellow);
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #FCD535;
    border: none;
    border-radius: var(--radii-m);
    color: var(--button-color);
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 500;
    gap: var(--space-3xs);
    height: 40px;
    justify-content: center;
    line-height: 24px;
    min-height: 40px;
    min-width: 72px;
    outline: none;
    overflow: hidden;
    padding-left: var(--space-m);
    padding-right: var(--space-m);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    word-break: break-all;
}

@media only screen and (min-width: 768px) {
    .bn-button:hover:not(.inactive) {
        opacity: .8;
    }
}

@media (min-width: 0px) and (max-width: 600px) {
  .earning-today-section  .py-\[64px\] {
        padding-bottom: 64px !important;
        padding-top: 0px !important;
    }

    .earning-today-section   .mb-\[40px\] {
        margin-bottom: 0px !important;
    }    
}