.privacy-policy-section{
    padding: 50px 0 40px 0;
}

.privacy-policy-title{
    padding: 40px 0;
    background-color: #014670;
    text-align: center;
}
.privacy-policy-title h5{
    font-size: 35px;
    color: #fff;
    font-weight: 600;
}
.privacy-policy{
    padding: 40px 0;
}
.privacy-policy p{
   font-size: 18px;
   line-height: 30px;
   font-weight: 400;
   padding-bottom: 20px;
   color: #555;
}
.privacy-policy p:nth-child(1){
   color: #000;
}
.privacy-policy p:nth-child(2){
   font-style: oblique;
}
.privacy-policy p:nth-child(4){
   font-size: 19px;
}




/* back  */


@media (min-width: 0px) and (max-width: 550px) {
    .disclaimer-lists ol li {
        padding-left: 0px !important;
        margin-left: 0px !important;
        padding:  0 !important;
    }
    
  .disclaimer-lists  ol, ul {
        padding-left: 0px !important;
    }

    .disclaimer-lists ol li:before{
        display: none;
    }

 }





 @media (min-width: 0px) and (max-width: 700px) {
    .benefits-content-area{
        padding-top: 50px;
        padding-bottom: 0;
    }
    }