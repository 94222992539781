.more-control-sectiona #transf:after {
  content: "";
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 330px;
  height: 330px;
  right: -165px;
  bottom: -166px;
  background: url("./graphic.png");
  z-index: 1;
}

.more-control-sectiona .section {
  /* padding: 90px 0; */
  position: relative;
}

.more-control-section .more-control-card-area {
  padding: 40px 0;
}
.more-control-section {
  padding-bottom: 50px;
}

.more-control-card {
  padding: 20px 50px;
  background-color: #13092f;
  border-radius: 10px;
  min-height: 340px;
}
.more-control-card h5 {
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
  padding: 10px 0;
}
.more-control-card p {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  --tw-text-opacity: 0.8;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 10px 0;
}
.more-control-card svg {
  width: 70px;
  height: 70px;
  padding: 20px;
  background-color: #0b0619;
  color: #fff;
  border-radius: 20px;
}
.more-control-title {
  text-align: center;
}

@media (min-width: 0px) and (max-width: 980px) {
  .more-control-mobile-container {
    max-width: 95% !important;
  }
}

@media (min-width: 780px) and (max-width: 980px) {
  .more-control-card {
    min-height: 385px;
  }
}
@media (min-width: 0px) and (max-width: 779.9px) {
  .more-control-card-area .col-6 {
    width: 95% !important;
    margin: auto;
  }
  .more-control-card {
    padding: 20px 25px;
  }
}



@media (min-width: 600px) and (max-width: 1000px) {
.more-control-sectiona #transf:after{
  width: 250px;
}

}

@media (min-width: 0px) and (max-width: 599.9px) {

.more-control-sectiona #transf:after{
  width: 250px;
}


}
