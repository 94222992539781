.billing-details-area h4{
    font-size: 1.5vw;
    color: #fff;
    margin-bottom: 10px;
}
.user-balance-area p{
    font-size: 1.2vw;
    color: rgb(112 114 116) ;
}
.user-balance-area span{
    font-size: 1vw;
    --tw-text-opacity: 1 ;
    color: rgb(1 102 252 / 1 ) ;
}
.user-freeze-balance-area p{
    font-size: 1.2vw;
    color: rgb(112 114 116) ;
}
.user-freeze-balance-area span{
    font-size: 1vw;
    color: rgb(184 184 184) ;
}
.records-area{
    padding: 30px 0;
}
.records-area h6{
    font-size: 1vw;
    color: #fff;
    font-weight: 300;
}
.records-items-lists{
    padding-top: 20px;
}
.records-items-lists p{
    font-size: .8vw;
    color: #838080;
    margin-bottom: 8px;
}
.records-items-lists span{
    font-size: .8vw;
    color: #fff;
}

.records-items{
    padding-bottom: 15px;
}