.user-kyc-data-item{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.user-kyc-data-item h5{
    font-size: 20px;
    line-height: 25px;
    margin-right: 15px;
}
.user-kyc-data-item p{
    font-size: 16px;
}
.user-kyc-data-status{
    font-size: 10px !important;
}