.Features___StyledDiv2-sc-rdq39d-1{
background-image: url("./single_faint.png") !important;
background-repeat: no-repeat;
background-position: bottom;
z-index: -1;
position: relative;
}

.dzpvBq {
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    height: 450px;
    width: 100%;
}


.fjkKjW {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding-top: 120px;
}

@media (min-width: 768px) {
    .ihxxPW {
        min-width: 650px !important;
    }
}

.ihxxPW {
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;
    max-width: 32rem;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(11 6 25 / var(--tw-bg-opacity));
    padding-bottom: 2rem;
    padding-top: 100px;
    text-align: center;
}

@media (min-width: 1024px) {
    .jLNEyf {
        font-size: 3.75rem;
    }
}

.jLNEyf {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 2.75rem;
    font-weight: 400;
    letter-spacing: -0.025em;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
    .fvzHHr {
        max-width: 32rem;
        font-weight: 500;
    }
}

.fvzHHr {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    max-width: 20rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    --tw-text-opacity: 0.8;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 0px) and (max-width: 980px) {
    .jLNEyf{
        font-size: 28px;
    }
    .dzpvBq{
        height: 350px;
    }
}

@media (min-width: 0px) and (max-width: 550px) {
    .fvzHHr{
        font-size: 18px;
        padding: 0 20px;
        margin: auto;
    }
}