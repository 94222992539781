.user-all-market-section{
    background-image: url("./bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
    margin-top: -100px;
    position: relative;
}
.user-all-market-section h5{
    font-size: 45px;
    line-height: 60px;
    color: #fff;
    font-weight: 600;
    width: 55%;
    margin: auto;
}
.user-all-market-section-img{
    position: absolute;
    bottom: -100px;
    left: 45%;
    width: 520px;
    height: 538px;
}

.user-all-market-section-img img{
    width: 100%;
    height: 100%;
}



.user-all-market-menu-area{
    padding-bottom: 30px;
    display: flex;
    align-items: end;
    justify-content: center;
    min-height: 300px;
}

.user-all-market-menu-area p{
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 600;
    margin-right: 50px;
    cursor: pointer;
    z-index: 1;
}

.user-all-market-menu-area .active{
    color: #0166FC;
    text-decoration: underline;
    text-underline-offset: 15px;
}

.user-all-market-data{
    padding-top: 30px;
}

.user-market-data .tradingview-widget-container{
    height: 70vh !important;
}