@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(229, 231, 235);
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: var(--tw-empty, /*!*/ /*!*/);
  --tw-pan-y: var(--tw-empty, /*!*/ /*!*/);
  --tw-pinch-zoom: var(--tw-empty, /*!*/ /*!*/);
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-gradient-via-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-gradient-to-position: var(--tw-empty, /*!*/ /*!*/);
  --tw-ordinal: var(--tw-empty, /*!*/ /*!*/);
  --tw-slashed-zero: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-figure: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-spacing: var(--tw-empty, /*!*/ /*!*/);
  --tw-numeric-fraction: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-saturate: var(--tw-empty, /*!*/ /*!*/);
  --tw-sepia: var(--tw-empty, /*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-blur: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-brightness: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-contrast: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-grayscale: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-hue-rotate: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-invert: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-opacity: var(--tw-empty, /*!*/ /*!*/);
  --tw-backdrop-saturate: var(--tw-empty, /*!*/ /*!*/);
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: Inter;
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  animation: 6s ease 0s infinite alternate none running gradient;
  margin: 0px;
}

a {
  text-decoration: none;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

p {
  margin: 0;
  padding: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}
ol,
ul {
  list-style: none;
}
.main-area {
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
}
svg {
  color: #fff;
}
::placeholder {
  color: #fff;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: button;
  background-color: transparent;
  background-image: none;
}

button,
[role="button"] {
  cursor: pointer;
}

button,
select {
  text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0px;
  padding: 0px;
  background-color: #1d113d;
}
input,
select,
textarea:focus {
  color: #fff;
  background-color: #1d113d;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

/*removes original number*/
.custom-lists ol {
  counter-reset: item;
  list-style-type: none;
}

.custom-lists ol li {
  display: block;
}

.custom-lists ol li:before {
  content: counter(item) ".";
  counter-increment: item;
}

/*large-number font*/
.custom-lists ol li:before {
  position: absolute;
  margin-left: -70px;
  font: bold italic 45px/1.5 Helvetica, Verdana, sans-serif;
  color: #ccc;
  top: 0;
}

/*Heading font*/
.custom-lists ol li {
  font: 18px Open Sans, sans-serif;
  line-height: 32px;
  padding-left: 60px;
  color: #000;
  position: relative;
}

/*paragraph font*/
.custom-lists li p {
  font: 16px Open Sans, sans-serif;
  line-height: 30px;
  color: #555;
}

/*width and style of list*/
.custom-lists ol {
  text-align: justify;
}

/*generic alignment*/
.custom-lists ol li {
  text-indent: 0 !important;
  padding-left: 20px !important;
  list-style-position: outside;
  margin-left: 20px;
  list-style-type: number;
}

.custom-lists ol li {
  text-align: left;
  text-indent: 0 !important;
  padding-left: 20px !important;
  list-style-position: outside;
  margin-left: 20px;
  padding: 15px 0;
}

.custom-main-section h5 {
  font-size: 30px;
  padding: 28px 0;
}

@media (min-width: 0px) and (max-width: 550px) {
  .custom-lists ol li {
    padding-left: 0px !important;
    margin-left: 0px !important;
    padding: 0 !important;
  }

  .custom-lists ol,
  ul {
    padding-left: 0px !important;
  }

  .custom-lists ol li:before {
    display: none;
  }
}

.form-control {
  color: #fff;
  background-color: #13092f;
  border: none;
}

.form-control:focus {
  color: #fff;
  background-color: #1d113d;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

.form-select {
  background-color: #13092f;
  color: #fff;
  border: none;
}

.form-select:focus {
  color: #fff;
  background-color: #1d113d;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}

label {
  color: #fff;
}

pre {
  color: #fff;
}

.user-submit-btn button {
  background-color: #0166fc;
  margin: auto;
  display: block;
}

.user-data-table h4 {
  padding: 20px 0;
}

.user-data-table .card {
  background-color: #1d113d !important;
}
.user-data-table table {
  --bs-table-bg: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
  padding: 10px 0;
  margin-bottom:0;
}

.user-data-table .table > :not(caption) > * > * {
  color: #fff;
  border-color: #1d113d;
}


.table-responsive {
  overflow-x: auto;
  /* padding-bottom: 0px !important; */
}

.table-responsive::-webkit-scrollbar {
  height: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #0075BB;
  outline: 1px solid #0075BB;
}



/* order filter area  */

.order-filter-area{
 padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.order-filter-area  p{
  padding-right: .5vw;
}
.chart-container {
  flex: 1;
}
