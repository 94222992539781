.user-dashboard-menu-section .modal-content {
    background: 0% 0% / 300% 300% no-repeat fixed rgb(11, 6, 25);
    animation: 6s ease 0s infinite alternate none running gradient;
    padding: 20px;
    min-height: 130vh;
}
.user-dashboard-menu-section .modal-content .modal-header {
    border-bottom: none;
}
.user-dashboard-menu-section .modal-body {
    padding: 0;
}
.user-dashboard-menu-section .user-dashboard-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.user-dashboard-menu-section .user-dashboard-menu-header h5{
   font-size: 18px;
   color: #fff;
}
.user-dashboard-menu-section .user-dashboard-menu-header svg{
   font-size: 18px;
   color: #fff;
   cursor: pointer;
}

.user-dashboard-menu-main-area{
    padding: 40px 0;
}

/* .user-dashboard-menu-main-left{
   text-align: center;
} */
.user-dashboard-menu-main-left p{
    border-radius: 20px;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px 0 10px 40px;
    font-weight: 400;
    font-size: 14px;
    list-style: 21px;
}

.user-dashboard-menu-main-left  .active{
    background-color: #0166FC;
}

.modal-body-details-area{
    height: 130vh;
    overflow-x: auto;
}

.modal-body-details-area::-webkit-scrollbar {
    display: none;
}

.modal-body-details-area {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}


.user-dashboard-menu-main-left p a{
    color: #fff;
}