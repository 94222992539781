.security-content {
  padding: 80px 0 50px 0;
}
.security-content h6 {
  font-size: 25px;
  color: #fff;
  padding-bottom: 15px;
}
.security-content p {
  font-size: 18px;
  color: #CECDD1;
}
.security-content img {
  margin: auto;
}


@media (min-width: 0px) and (max-width: 700px) {
.security-content .row{
  display: grid !important;
  
}
.security-content .row .col-6{
  width: 100% !important;
}
}